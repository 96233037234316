<script>
    
    import { bank_list } from "@/api/my";
    export default {
        name:'Withdraw_BankCard',
        components: {
            Header: () => import('components/base/Header'),
            GetBankMessage: () => import('views/my/cash_withdrawal/get_bank_message'),

            'mt-header':app.Mint.Header,
            'mt-button':app.Mint.Button,
        },
        data() {
            return {
                bankbbool: true,
                bank: "",
                bank_num: "",
                bank_name: "",
                cardTypeName: "",

                header: {
                    mainTitle: getWord('withdraw'),
                },
            };
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },
            search_bank() {
                bank_list().then(result => {
                    if (result.data.code == "SUCCESS") {
                        if (!result.data.result.length == 0) {
                            this.bankbbool = false;
                        }
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            }
        },
        mounted() {
            this.search_bank();
        },
    };
</script>

<template>
    <section id="personal" :class="_TEMPLATE">
        <template v-if="bankbbool">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <img :src="require('@@/assets/'+this._TEMPLATE+'/images/personal/bg/withdraw.png')" />
            <p>{{ getWord('bankcard_tips') }}</p>
            <router-link to="/my/my_bank/add_bank">
                {{ getWord('add_bankcard2') }}
            </router-link>
        </template>
        <template v-if="!bankbbool">
            <GetBankMessage />
        </template>
    </section>
</template>

<style scoped lang="scss" type="text/css">
#personal {

    &.template-1,
    &.template-2,
    &.template-3 {
        background-color: #EEEEEE;
        height: 100%;

        > a {
            color: #FFFFFF;
            font-size: .34rem;
            padding: .275rem 0;
            width: 90%;
            background-color: #EC2829;
            display: block;
            text-align: center;
            margin: .75rem auto;
            border-radius: .15rem;
        }

        > p {
            color: #B0B0B0;
            font-size: .28rem;
            text-align: center;
        }

        > img {
            width: 1.6rem;
            display: block;
            margin: 1rem auto .25rem;
        }
    }

    &.template-2 {

        > img {
            width: auto;
        }
    }

    &.template-1,
    &.template-2 {
        background-color: #ffffff;

        > a {
            background-color: #26a2ff;
        }
    }
}
.main_image {
    float: left;
    height: 1.46rem;
    width: 1.45rem;
    background-image: url('/static/assets/images2/user/mybank/zwyhk.png');
    background-size: 100%;
    margin: 2.4rem 0 0 3rem;
}

.main span {
    float: left;
    width: 4rem;
    height: 0.25rem;
    font-size: 0.25rem;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    margin: 0.4rem 0 0 2rem;
}

.main_button {
    float: left;
    width: 3.98rem;
    height: 0.68rem;
    margin: 0.6rem 0 0 1.8rem;
}

.jh {
    float: left;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url('/static/assets/images2/user/mybank/jh.png');
    background-size: 100%;
    margin-top: 0.1rem;
}
</style>
